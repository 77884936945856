import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import webring_dark from './webring_dark.svg';
import webring_light from './webring_light.svg';


// TODO:
// - make page responsive for mobile devices
// - move Button components to separate files
// - smooth transition for color theme change
// - factor in friends' feedback
// - add download button for resume

function LightButton() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="icon" // Note: Use `className` instead of `class` in JSX
      width="30"
      height="30"
      fill="#fff"
    >
      <path d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z"></path>
    </svg>
  );
}


function DarkButton() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="icon" // Use `className` instead of `class` in JSX
      width="30"
      height="30"
      fill="#3c3c3c"
    >
      <path d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z"></path>
    </svg>
  );
}

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
  }, [isDarkMode]);

  //<div className="section">
  //  <div className="section-name">Resume</div>
  //  <div className="section-content">
  //    <p>
  //      <a href="/resume.pdf" target="_blank">Winter '25 Resume</a>
  //    </p>
  //  </div>
  //</div>

  //<div className="blog-container">
  //  <div className="blog-title">Blog</div>
  //</div>

// resume saved here is external_fullstack
  return (
    <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
      <div className="main">
        <div className="section">
          <button onClick={toggleTheme} className="theme-button">
            {isDarkMode ? <LightButton /> : <DarkButton />}
          </button>
        </div>
        <br />
        <div className="section">
          <div className="section-name">Intro</div>
          <div className="section-content">
            <p>Hey, I'm Krish. I'm a second year SE student at the University of Waterloo.</p>
          </div>
        </div>
        <br />
        <br />
        <div className="section">
          <div className="section-name">GitHub</div>
          <div className="section-content">
            <p>
              <a href="https://github.com/isobarbaric" target="_blank">isobarbaric</a>
            </p>
          </div>
        </div>
        <br />
        <div className="section">
          <div className="section-name">Contact</div>
          <div className="section-content">
            <p><a href="https://www.linkedin.com/in/kri-shah/" target="_blank">LinkedIn</a></p>
            <p>shahkrish2016[at]gmail.com</p>
            <p>k33shah[at]uwaterloo.ca</p>
          </div>
        </div>
        <br />
        <div 
          className="webring" 
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          <a href="https://se-webring.xyz/" target="_blank">
            {isDarkMode ? 
              <img src={require('./webring_dark.svg').default} width="40" alt='dark-se-webring' /> :
              <img src={require('./webring_light.svg').default} width="40" alt='light-se-webring' />}
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
